import PolitiquedeconfidentialiteContent from "./PolitiquedeconfidentialiteContent";

import CookieConsent from "react-cookie-consent";
import "./style.css"
function Politiquedeconfidentialite() {
  return (
    <>
       
      <PolitiquedeconfidentialiteContent />
 

      <CookieConsent
        location="bottom"
        buttonText="J'accepte !"
        cookieName="politque-de-confidentialite"
        style={{ background: "transparent" }}
        buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
        expires={30}>
    </CookieConsent>
    </>
  );
}

export default Politiquedeconfidentialite;
