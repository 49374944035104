import fms500one from "../../images/FMS-500-One.png";
import fms500stcan from "../../images/FMS500-StCAN.png";
import fms500light from "../../images/FMS500-LIGHT.png";
import fms500lightP from "../../images/FMS500-LIGHT-P.png";
import GT710 from "../../images/Concox-GT710.jpg";
import at4 from "../../images/at4.jpeg";
import teltonikafmb120 from "../../images/teltonikafmb120.png";
import fmb920 from "../../images/fmb920.png";
import fmb140 from "../../images/fmb140.jpeg";
const produitsData = [
  {
    name: "FMS500 ONE",
    description:
      "Simple et performant, il n’y pas mieux que le dispositif FMS500 One pour répondre aux exigences de géolocalisation en temps réel. Sa configuration est basique et son installation est facile. Il est doté d’un accélérométre interne configurable, d’un anti-brouilleur et d’un détecteur de choc intégré…",
    img: fms500one,
  },
  {
    name: "FMS500 STCAN",
    description:
      "Le dispositif FMS500 StCan est la référence la plus avancée de la gamme FMS500. En plus de l’ensemble des fonctionnalités implémentées dans les autres gammes, il supporte deux lignes Can Bus qui fonctionnent simultanément, il permet ainsi de collecter l’ensemble des informations centralisées dans les ordinateurs…",
    img: fms500stcan,
  },
  {
    name: "FMS500 Light",
    description:
      "Le dispositif FMS500 Light est un dispositif de nouvelle génération susceptible d’être connecté avec une variété d’accessoires : Sonde de carburant, Débitmètres de carburant, capteurs d’état (Portes, capot, …), sondes de température et d’humidité, identification des conducteurs et des passagers…",
    img: fms500light,
  },
  {
    name: "FMS500 Light+",
    description:
      "Le dispositif FMS500 Light+ est une version avancée du boitier Light. Outre, son adaptabilité avec une diversité de capteurs, cette gamme possède trois interfaces : RS232, RS485 et Wiegand 26. Cette varieté technologique permet à cet équipement de répondre…",
    img: fms500lightP,
  },
  {
    name: "Concox GT710 Sans Fil",
    description:
      "Afin de fournir une prestation de haute sécurité, les dispositifs GT710 sont mis à disposition des clients comme équipement de back-up. Avec sa batterie de 3 ans d’autonomie, son aimant de fixation et son étanchéité, ce dispositif sans-fil peut être installé dans des emplacements totalement isolés…",
    img: GT710,
  },
  {
    name: "Concox AT4 Sans Fil",
    description:
      "Ses fonctionnalités avancées, sa construction robuste et la durée de vie de sa batterie à la pointe de l’industrie font de l’AT4 le premier système au monde de suivi par GPS de pointe Sans Fil. Il est muni d’un aimant de fixation puissant, une batterie rechargeable de 10000mAh et une protection IPX5 résistante…",
    img: at4,
  },
  {
    name: "Teltonika fmb120",
    description:
      "FMB120 petit tracker professionnel avec antennes GSM et GNSS internes à gain élevé, qui est capable de collecter les coordonnées de l’appareil et d’autres données utiles et de les transférer via le réseau GSM vers le serveur. Cet appareil est parfaitement adapté aux applications où l’acquisition d’emplacement d’objets distants est nécessaire: gestion de flotte, sociétés de location de voitures, sociétés de taxi, transports publics, sociétés de logistique, voitures personnelles, etc.",
    img: teltonikafmb120,
  },
  {
    name: "teltonika fmb920",
    description:
      "Teltonika Le FMB920 est un tracker compact et intelligent avec connectivité Bluetooth, antennes internes GNSS et GSM à gain élevé et batterie de secours intégrée. Le FMB920 est conçu pour le suivi des véhicules légers dans des applications telles que la télématique d’assurance, les voitures de location, la récupération de voitures volées, les services de sécurité publique, le transport de livraison, le taxi et bien plus encore. Les entrées / sorties étendent les scénarios d’utilisation des appareils ",
    img: fmb920,
  },
  {
    name: "teltonika fmb140",
    description:
      "Le FMB140 est un tracker GSM / GNSS / Bluetooth AVANCÉ avec processeur de données CAN intégré. Il est compact Solution 2 en 1: tracker GPS et adaptateur CAN à l’intérieur! L’appareil permet de lire les données CAN d’une large gamme de véhicules divers, y compris les véhicules légers et électriques, les camions, les bus et les machines spéciales. Selon la version exacte du logiciel, le FMB140 peut être utilisé dans des applications avancées telles que la logistique lourde, les services de livraison, le transport public.",
    img: fmb140,
  },
];

export default produitsData;
