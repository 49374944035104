import React from "react";
import "./style.css";
const PolitiquedeconfidentialiteContent = () => {
  return (
    <main id="Politiquedeconfidentialite">
        <section >
            <div id="PolitiquedeconfidentialiteContent">
      <p class="privacy-content-p">GPS4WORLD</p>
<p class="privacy-content-p">10 juin 2022</p>
<p class="privacy-content-p">POLITIQUE DE CONFIDENTIALIT</p>
<p class="privacy-content-p">Cette politique de confidentialit&eacute; d&eacute;finit la mani&egrave;re dont GPS4WORLD (&laquo; Soci&eacute;t&eacute; &raquo;, &laquo; nous &raquo;, &laquo; notre &raquo; ou &laquo; notre &raquo;) collecte et traite les informations via notre site Web, notre application mobile et les services associ&eacute;s (collectivement, les &laquo; Services &raquo;). Veuillez lire attentivement cette politique de confidentialit&eacute; pour comprendre nos politiques et pratiques concernant vos informations et comment nous traiterons ces informations. Si vous n&apos;&ecirc;tes pas d&apos;accord avec nos politiques et pratiques, vous ne devez pas utiliser nos Services. En acc&eacute;dant ou en utilisant nos services, vous acceptez cette politique de confidentialit&eacute;.</p>
<p class="privacy-content-p">Accessibilit&eacute; : Si vous rencontrez des difficult&eacute;s pour acc&eacute;der &agrave; cette politique de confidentialit&eacute;, veuillez nous contacter au 212660559129. Nos heures d&apos;ouverture sont les suivantes : du lundi au vendredi de 8 h 00 &agrave; 19 h 00 et le samedi du 8h30 &agrave; 13h00.</p>
<p class="privacy-content-p">APER&Ccedil;U</p>
<p class="privacy-content-p">Notre service peut collecter votre emplacement pr&eacute;cis l&apos;activit&eacute; du v&eacute;hicule, la messagerie via votre appareil et d&apos;autres informations.</p>
<p class="privacy-content-p">Votre compte pour le Service peut &ecirc;tre g&eacute;r&eacute; par un administrateur de votre employeur (&quot;Votre Administrateur&quot;). Si tel est le cas, pour tout probl&egrave;me, question ou demande concernant votre compte, vous devez d&apos;abord contacter votre administrateur.</p>
<p class="privacy-content-p">Notre service peut collecter automatiquement certaines informations sur vous et votre v&eacute;hicule. Comme le nom de l&apos;entreprise l&apos;indique, notre service peut suivre votre position.</p>
<p class="privacy-content-p">Vous avez la possibilit&eacute; de contr&ocirc;ler la mani&egrave;re dont certaines informations sont collect&eacute;es et utilis&eacute;es, comme d&eacute;crit ci-dessous.</p>
<p class="privacy-content-p">Si vous r&eacute;sidez en Californie, veuillez consulter la section &laquo; Droits des utilisateurs en Californie &raquo; ci-dessous.</p>
<p class="privacy-content-p">Si vous &ecirc;tes un r&eacute;sident de l&apos;Union europ&eacute;enne ou du Royaume-Uni, veuillez consulter la section &laquo; Droits des utilisateurs UE/Royaume-Uni &raquo; ci-dessous.</p>
<p class="privacy-content-p">COLLECTE D&apos;INFORMATIONS</p>
<p class="privacy-content-p">Notre service recueille des informations qui identifient une personne en particulier (&laquo;&nbsp;informations personnelles&nbsp;&raquo;). La Soci&eacute;t&eacute; collecte les cat&eacute;gories de donn&eacute;es personnelles suivantes :</p>
<p class="privacy-content-p">Cat&eacute;gorie</p>
<p class="privacy-content-p">Exemples Objectifs pour lesquels ces informations ont &eacute;t&eacute; collect&eacute;es Cat&eacute;gories de sources &agrave; partir desquelles des informations personnelles ont &eacute;t&eacute; collect&eacute;es</p>
<p class="privacy-content-p">Cat&eacute;gories de tiers avec lesquels nos services partagent des informations personnelles</p>
<p class="privacy-content-p">Identifiants personnels nom, adresse physique, adresse e-mail, num&eacute;ro de t&eacute;l&eacute;phone et informations similaires. Pour activer et g&eacute;rer votre compte. Pour faire fonctionner le Service. Pour nous permettre de vous contacter. De votre administrateur lors de la cr&eacute;ation de votre compte.</p>
 
<p class="privacy-content-p">Directement de vous lorsque vous nous les fournissez volontairement.</p>
<p class="privacy-content-p">Les fournisseurs de services qui aident &agrave; exploiter nos services et comme autrement d&eacute;crit ci-dessous.</p>
<p class="privacy-content-p">Votre administrateur peut &eacute;galement avoir acc&egrave;s &agrave; ces informations.</p>
<p class="privacy-content-p">Activit&eacute; de navigation Historique de navigation, historique de recherche, informations sur l&apos;interaction d&apos;un individu avec nos Services. Pour exploiter et personnaliser nos Services, analyser et am&eacute;liorer l&apos;utilisation de nos Services, d&eacute;velopper des analyses, &agrave; des fins de test et de recherche et comme autrement d&eacute;crit ci-dessous. Directement depuis vos appareils en observant vos actions via nos Services.</p>
<p class="privacy-content-p">Les fournisseurs de services qui aident &agrave; exploiter nos services et comme autrement d&eacute;crit ci-dessous.</p>
<p class="privacy-content-p">Votre administrateur peut &eacute;galement avoir acc&egrave;s &agrave; ces informations.</p>
<p class="privacy-content-p">Donn&eacute;es de g&eacute;olocalisation Votre position g&eacute;ographique pr&eacute;cise ou g&eacute;n&eacute;rale. Pour exploiter et personnaliser nos Services, analyser et am&eacute;liorer l&apos;utilisation de nos Services, d&eacute;velopper des analyses, &agrave; des fins de test et de recherche et comme autrement d&eacute;crit ci-dessous. Directement depuis vos appareils en observant vos actions via nos Services. Les fournisseurs de services qui aident &agrave; exploiter nos services.</p>
<p class="privacy-content-p">Votre administrateur peut &eacute;galement avoir acc&egrave;s &agrave; ces informations.</p>
<p class="privacy-content-p">Informations biom&eacute;triques Contenu audio et/ou vid&eacute;o via notre application mobile. Pour exploiter et personnaliser nos Services, analyser et am&eacute;liorer l&apos;utilisation de nos Services, d&eacute;velopper des analyses, &agrave; des fins de test et de recherche et comme autrement d&eacute;crit ci-dessous. Directement depuis vos appareils en observant vos actions via nos Services. Les fournisseurs de services qui aident &agrave; exploiter nos services.</p>
<p class="privacy-content-p">Votre administrateur peut &eacute;galement avoir acc&egrave;s &agrave; ces informations.</p>
<p class="privacy-content-p">Informations sur l&apos;appareil Appareil photo de l&apos;appareil, &eacute;tat de l&apos;appareil (c&apos;est-&agrave;-dire s&apos;il y a un appel actif), messages SMS transmis Pour exploiter et personnaliser nos Services, analyser et am&eacute;liorer l&apos;utilisation de nos Services, d&eacute;velopper des analyses, &agrave; des fins de test et de recherche et comme autrement d&eacute;crit ci-dessous. Directement depuis vos appareils en observant vos actions via nos Services. Les fournisseurs de services qui aident &agrave; exploiter nos services.</p>
<p class="privacy-content-p">Votre administrateur peut &eacute;galement avoir acc&egrave;s &agrave; ces informations.</p>
<p class="privacy-content-p">Nous recueillons &eacute;galement des informations qui ne sont pas, en elles-m&ecirc;mes, des informations personnelles, telles que les activit&eacute;s du v&eacute;hicule que vous conduisez lorsqu&apos;un appareil utilisant nos Services est install&eacute;, pr&eacute;sent ou activ&eacute;.</p>
<p class="privacy-content-p">COOKIES ET M&Eacute;THODES SIMILAIRES</p>
<p class="privacy-content-p">Les informations de navigation sont collect&eacute;es automatiquement par divers moyens tels que les cookies et les pixels via nos services ou dans nos e-mails. Les &laquo; cookies &raquo; sont des &eacute;l&eacute;ments d&apos;information qui peuvent &ecirc;tre plac&eacute;s sur votre appareil par un service dans le but de faciliter et d&apos;am&eacute;liorer votre communication et votre interaction avec ce service. De nombreux services utilisent des cookies &agrave; ces fins. Nous pouvons utiliser des cookies (et des &eacute;l&eacute;ments similaires tels que des gifts invisibles, des balises Web, des balises, etc.) via nos Services pour personnaliser votre visite, pour analyser la fa&ccedil;on dont vous utilisez nos Services et &agrave; d&apos;autres fins pour rendre votre visite plus pratique, pour g&eacute;rer votre compte ou pour nous permettre d&apos;am&eacute;liorer nos services. Les cookies et &eacute;l&eacute;ments similaires ne sont pas utilis&eacute;s par nous pour r&eacute;cup&eacute;rer automatiquement des informations personnellement identifiables de votre appareil &agrave; votre insu, mais peuvent &ecirc;tre utilis&eacute;s pour stocker vos identifiants de connexion.</p>
<p class="privacy-content-p">Nous pouvons utiliser Google Analytics, qui est un service d&apos;analyse Web fourni par Google, Inc. Google et des services similaires collectent certaines informations relatives &agrave; votre utilisation de nos Services. Ces services peuvent utiliser des cookies pour nous aider &agrave; analyser la mani&egrave;re dont les utilisateurs utilisent nos Services. Vous pouvez en savoir plus sur la mani&egrave;re dont Google utilise les donn&eacute;es lorsque vous utilisez nos Services en visitant &quot;Comment Google utilise les donn&eacute;es lorsque vous utilisez les sites ou applications de nos partenaires&quot; (situ&eacute; &agrave; l&apos;adresse www.google.com/policies/privacy/partners/).</p>
<p class="privacy-content-p">En fonction de votre navigateur Web, vous pouvez avoir la possibilit&eacute; d&apos;accepter ou de refuser les cookies. La plupart des navigateurs Web acceptent automatiquement les cookies, mais vous pouvez g&eacute;n&eacute;ralement modifier les param&egrave;tres de votre navigateur pour refuser les cookies si vous pr&eacute;f&eacute;rez. Si vous choisissez de refuser les cookies, vous ne pourrez peut-&ecirc;tre pas profiter pleinement des fonctionnalit&eacute;s interactives des services. De plus amples informations sur les cookies sont disponibles sur www.allaboutcookies.org.</p>
<p class="privacy-content-p">COMMENT NOUS UTILISONS VOS INFORMATIONS</p>
<p class="privacy-content-p">Nous utilisons les informations que nous recueillons aupr&egrave;s de vous uniquement de la mani&egrave;re d&eacute;crite dans la pr&eacute;sente politique de confidentialit&eacute;, avec l&apos;autorisation de votre administrateur ou comme autrement divulgu&eacute; au moment de la collecte&nbsp;:</p>
<p class="privacy-content-p">Pour exploiter et vous fournir nos Services&nbsp;;</p>
<p class="privacy-content-p">Pour communiquer &agrave; votre administrateur des informations sur l&apos;utilisation et les activit&eacute;s de votre v&eacute;hicule, notamment via l&apos;utilisation d&apos;un portail en ligne et d&apos;un syst&egrave;me de signalement&nbsp;;</p>
<p class="privacy-content-p">Pour vous fournir les informations que vous nous demandez ;</p>
<p class="privacy-content-p">Pour vous commercialiser des produits&nbsp;;</p>
<p class="privacy-content-p">Pour g&eacute;rer votre compte, y compris en vous coordonnant avec votre administrateur&nbsp;;</p>
<p class="privacy-content-p">Pour analyser et am&eacute;liorer nos Services&nbsp;; et</p>
<p class="privacy-content-p">Pour toute autre fin avec votre consentement.</p>
<p class="privacy-content-p">DIVULGATION DE VOS INFORMATIONS</p>
<p class="privacy-content-p">Nous pouvons divulguer vos informations comme d&eacute;crit dans la pr&eacute;sente politique de confidentialit&eacute; ou autrement avec votre consentement&nbsp;:</p>
<p class="privacy-content-p">Aux sous-traitants, prestataires de services et autres tiers auxquels nous faisons appel pour nous aider &agrave; exploiter nos Services. Cependant, ces parties ne sont autoris&eacute;es par nous qu&apos;&agrave; utiliser les informations pour notre b&eacute;n&eacute;fice&nbsp;;</p>
<p class="privacy-content-p">&Agrave; votre administrateur&nbsp;;</p>
<p class="privacy-content-p">&Agrave; un acheteur ou autre successeur en cas de fusion, cession, restructuration, r&eacute;organisation, dissolution ou autre vente ou transfert de tout ou partie de nos actifs, que ce soit en tant qu&apos;entreprise en exploitation ou dans le cadre d&apos;une faillite, liquidation ou proc&eacute;dure similaire , dans lequel les informations d&eacute;tenues par la Soci&eacute;t&eacute; sur les utilisateurs de nos Services font partie des actifs transf&eacute;r&eacute;s&nbsp;;</p>
<p class="privacy-content-p">Pour se conformer &agrave; toute ordonnance d&apos;un tribunal, loi ou proc&eacute;dure judiciaire, y compris pour r&eacute;pondre &agrave; toute demande gouvernementale ou r&eacute;glementaire l&eacute;gale&nbsp;;</p>
<p class="privacy-content-p">Pour faire respecter ou appliquer nos conditions, politiques ou enqu&ecirc;ter sur une fraude&nbsp;;</p>
<p class="privacy-content-p">&Agrave; des fins de s&ucirc;ret&eacute; et de s&eacute;curit&eacute;&nbsp;; et</p>
<p class="privacy-content-p">Si nous pensons que la divulgation est n&eacute;cessaire ou appropri&eacute;e pour prot&eacute;ger les droits, la propri&eacute;t&eacute; ou la s&eacute;curit&eacute; de la Soci&eacute;t&eacute;, de nos utilisateurs ou de tiers.</p>
<p class="privacy-content-p">Informations de navigation</p>
<p class="privacy-content-p">Les informations de navigation et les informations d&apos;utilisation du service non personnellement identifiables peuvent &ecirc;tre divulgu&eacute;es &agrave; un certain nombre de tiers et utilis&eacute;es &agrave; diverses fins tant qu&apos;elles ne sont pas directement li&eacute;es &agrave; vos informations personnellement identifiables.</p>
<p class="privacy-content-p">ANNONCES BAS&Eacute;ES SUR LES INT&Eacute;R&Ecirc;TS</p>
<p class="privacy-content-p">Nous pensons que la publicit&eacute; est plus efficace et pertinente lorsqu&apos;elle est cibl&eacute;e sur vos centres d&apos;int&eacute;r&ecirc;t et vos comportements. Par cons&eacute;quent, nous pouvons travailler avec des tiers qui collectent des informations sur nos Services via l&apos;utilisation de cookies et de m&eacute;thodes similaires afin de vous proposer des publicit&eacute;s pertinentes sur d&apos;autres services ou de d&eacute;terminer que vous avez vu nos publicit&eacute;s sur d&apos;autres services. Vous avez la possibilit&eacute; de contr&ocirc;ler certaines pratiques publicitaires. Nous respectons les principes d&apos;autor&eacute;gulation pour la publicit&eacute; comportementale en ligne tels qu&apos;administr&eacute;s par la Digital Advertising Alliance (DAA). Vous pouvez en savoir plus sur la publicit&eacute; cibl&eacute;e par centres d&apos;int&eacute;r&ecirc;t et la d&eacute;sactivation de la publicit&eacute; cibl&eacute;e de certains fournisseurs avec lesquels nous travaillons en visitant https://youradchoices.com/control et http://www.aboutads.info/choices.</p>
<p class="privacy-content-p">Vous pouvez choisir de ne pas &ecirc;tre suivi en ligne par certaines soci&eacute;t&eacute;s r&eacute;pertori&eacute;es sur http://www.aboutads.info/choices/ OU http://www.networkadvertising.org/managing/opt_out.asp et pouvez &eacute;galement en savoir plus sur la publicit&eacute; comportementale en ligne sur ces sites Web. Si vous vous d&eacute;sabonnez, vous recevrez toujours des publicit&eacute;s, mais elles ne vous seront pas fournies par les soci&eacute;t&eacute;s dont vous vous &ecirc;tes d&eacute;sinscrit sur la base de vos donn&eacute;es comportementales d&eacute;tenues par les soci&eacute;t&eacute;s dont vous vous &ecirc;tes d&eacute;sabonn&eacute;.</p>
<p class="privacy-content-p">Le processus de d&eacute;sactivation via le DAA repose sur le placement d&apos;un cookie de d&eacute;sactivation sur votre appareil, et vous devez r&eacute;p&eacute;ter ce processus sur chaque appareil ou si vos cookies sont supprim&eacute;s de votre appareil. Les d&eacute;sactivations bas&eacute;es sur les cookies ne sont pas effectives sur certains services mobiles. Les utilisateurs peuvent d&eacute;sactiver certaines publicit&eacute;s sur les applications mobiles ou r&eacute;initialiser les identifiants publicitaires via les param&egrave;tres de leur appareil. Pour savoir comment limiter le suivi publicitaire ou r&eacute;initialiser l&apos;identifiant publicitaire sur votre appareil iOS et Android, cliquez sur les liens suivants&nbsp;:</p>
<p class="privacy-content-p">iOS &ndash; https://support.apple.com/en-us/HT202074</p>
<p class="privacy-content-p">Android &ndash; https://support.google.com/ads/answer/2662922?hl=en</p>
<p class="privacy-content-p">Vous pouvez &eacute;galement t&eacute;l&eacute;charger et d&eacute;finir vos pr&eacute;f&eacute;rences sur les applications mobiles App Choices de DAA disponibles sur Google Play ou les magasins d&apos;applications Apple. Plus d&apos;informations sur la d&eacute;sactivation sur les appareils mobiles sont disponibles ici - https://www.networkadvertising.org/mobile-choice/</p>
<p class="privacy-content-p">Nous ne r&eacute;pondons pas ou n&apos;honorons pas les signaux &quot;ne pas suivre&quot; (a/k/a DNT) ou m&eacute;canismes similaires transmis automatiquement par les navigateurs Web pour lesquels nous ne pouvons pas &eacute;valuer votre choix</p>
<p class="privacy-content-p">SERVICES TIERS</p>
<p class="privacy-content-p">Ce service contient des liens et des fonctionnalit&eacute;s interactives avec divers tiers, tels que des plateformes de m&eacute;dias sociaux. Nous n&apos;approuvons ni n&apos;assumons la responsabilit&eacute; des services de tiers simplement parce qu&apos;un lien appara&icirc;t sur notre service. Si vous utilisez d&eacute;j&agrave; ces plateformes, leurs cookies peuvent &ecirc;tre install&eacute;s sur votre appareil lors de l&apos;utilisation de notre Service et ces fournisseurs peuvent d&eacute;j&agrave; vous suivre et collecter vos informations. Nous ne sommes pas en mesure de contr&ocirc;ler ces utilisations de vos informations lors de l&apos;interaction avec une plate-forme de m&eacute;dias sociaux, et en utilisant ces services, vous assumez le risque que les informations personnellement identifiables que vous fournissez puissent &ecirc;tre consult&eacute;es et utilis&eacute;es par des tiers &agrave; diverses fins.</p>
<p class="privacy-content-p">LA CONSERVATION DES DONN&Eacute;ES</p>
<p class="privacy-content-p">Nous conserverons vos informations aussi longtemps que votre compte sera actif ou aussi longtemps que n&eacute;cessaire pour g&eacute;rer et fournir nos services et pour nos besoins commerciaux. M&ecirc;me si nous supprimons tout ou partie de vos informations personnelles, nous pouvons continuer &agrave; conserver et &agrave; utiliser des donn&eacute;es anonymes ou agr&eacute;g&eacute;es pr&eacute;c&eacute;demment collect&eacute;es.</p>
<p class="privacy-content-p">SE D&Eacute;SENGAGER</p>
<p class="privacy-content-p">Vous pouvez vous d&eacute;sinscrire de nos e-mails promotionnels &agrave; tout moment en nous envoyant un e-mail &agrave; [EMAIL ADRESSS], et nous traiterons votre demande sous peu.</p>
<p class="privacy-content-p">Vous pouvez bloquer les cookies tiers sur votre appareil comme d&eacute;crit ci-dessus.</p>
<p class="privacy-content-p">Vous pouvez arr&ecirc;ter l&apos;utilisation des donn&eacute;es pour la publicit&eacute; cibl&eacute;e par centres d&apos;int&eacute;r&ecirc;t, comme d&eacute;crit dans la section &quot;Publicit&eacute;s bas&eacute;es sur les centres d&apos;int&eacute;r&ecirc;t&quot; ci-dessus.</p>
<p class="privacy-content-p">Si vous r&eacute;sidez en Californie, vous disposez de certains droits suppl&eacute;mentaires d&eacute;crits ci-dessous.</p>
<p class="privacy-content-p">Si vous &ecirc;tes un r&eacute;sident de l&apos;UE ou du Royaume-Uni, vous disposez de certains droits suppl&eacute;mentaires d&eacute;crits ci-dessous.</p>
<p class="privacy-content-p">Comme mentionn&eacute; ci-dessus, vous devez d&apos;abord adresser les demandes concernant votre acc&egrave;s et votre utilisation du service &agrave; votre administrateur.</p>
<p class="privacy-content-p">DROITS DES UTILISATEURS EN CALIFORNIE</p>
<p class="privacy-content-p">Cette section s&apos;applique &agrave; vous si vous &ecirc;tes un r&eacute;sident de Californie et un consommateur b&eacute;n&eacute;ficiant de droits en vertu du California Consumer Privacy Act (CCPA) ou si vous disposez de droits similaires en vertu de la loi de votre juridiction. Comme mentionn&eacute; ci-dessus, vous devez d&apos;abord adresser vos demandes &agrave; votre administrateur, car nous pouvons simplement &ecirc;tre un fournisseur de services pour votre administrateur pour certaines informations. En outre, certains droits de l&apos;ACCP peuvent ne pas s&apos;appliquer &agrave; vous lorsque des informations personnelles sont trait&eacute;es dans le cadre de votre emploi ou de vos relations d&apos;affaires.</p>
<p class="privacy-content-p">Droit de conna&icirc;tre les renseignements personnels recueillis, divulgu&eacute;s ou vendus</p>
<p class="privacy-content-p">Vous avez le droit de demander que nous vous divulguions certaines informations sur notre collecte, utilisation, divulgation ou vente de vos informations personnelles au cours des 12 derniers mois. Une fois que nous aurons re&ccedil;u et confirm&eacute; votre demande de consommateur v&eacute;rifiable (voir Exercice des droits d&apos;acc&egrave;s, de suppression et de retrait), et sous r&eacute;serve de certaines limitations que nous d&eacute;crivons ci-dessous, nous vous divulguerons ces informations.</p>
<p class="privacy-content-p">Vous avez le droit de demander tout ou partie des &eacute;l&eacute;ments suivants&nbsp;:</p>
<p class="privacy-content-p">Les cat&eacute;gories d&apos;informations personnelles que nous avons collect&eacute;es &agrave; votre sujet.</p>
<p class="privacy-content-p">Les cat&eacute;gories de sources &agrave; partir desquelles les informations personnelles sont collect&eacute;es.</p>
<p class="privacy-content-p">Notre objectif commercial ou commercial pour la collecte ou la vente de ces informations personnelles.</p>
<p class="privacy-content-p">Les cat&eacute;gories de tiers avec lesquels nous partageons ces informations personnelles.</p>
<p class="privacy-content-p">Les informations personnelles sp&eacute;cifiques que nous avons collect&eacute;es &agrave; votre sujet.</p>
<p class="privacy-content-p">Droit de demander la suppression</p>
<p class="privacy-content-p">Vous avez le droit de demander que nous supprimions toutes vos informations personnelles que nous avons collect&eacute;es aupr&egrave;s de vous et conserv&eacute;es, sous r&eacute;serve de certaines exceptions. Une fois que nous aurons re&ccedil;u et confirm&eacute; votre demande individuelle v&eacute;rifiable (voir Exercice des droits d&apos;acc&egrave;s et de suppression), nous supprimerons (et demanderons &agrave; nos fournisseurs de services de supprimer) vos informations personnelles de nos dossiers. Cependant, nous pouvons conserver des informations personnelles qui ont &eacute;t&eacute; antonymies ou agr&eacute;g&eacute;es. En outre, nous pouvons refuser votre demande de suppression si la conservation des informations est n&eacute;cessaire pour nous ou nos prestataires de services pour effectuer certaines actions, telles que la d&eacute;tection d&apos;incidents de s&eacute;curit&eacute; et la protection contre les activit&eacute;s ill&eacute;gales.</p>
<p class="privacy-content-p">Aucune vente d&apos;informations personnelles</p>
<p class="privacy-content-p">Nous ne &laquo; vendons &raquo; pas vos informations personnelles, car ce terme de vente est d&eacute;fini dans le CCPA.</p>
<p class="privacy-content-p">Exercice des droits d&apos;acc&egrave;s et de suppression</p>
<p class="privacy-content-p">Pour exercer les droits d&apos;acc&egrave;s et de suppression d&eacute;crits ci-dessus, veuillez nous adresser une demande soit :</p>
<p class="privacy-content-p">En nous envoyant un e-mail &agrave; contact@gps4world.ma</p>
<p class="privacy-content-p">Ou Appelez-nous au 212660559129</p>
<p class="privacy-content-p">La demande doit inclure vos coordonn&eacute;es et d&eacute;crire votre demande avec suffisamment de d&eacute;tails pour nous permettre de la comprendre, de l&apos;&eacute;valuer et d&apos;y r&eacute;pondre correctement. En outre, vous devez fournir suffisamment d&apos;informations nous permettant de v&eacute;rifier raisonnablement que vous &ecirc;tes la personne &agrave; propos de laquelle nous avons collect&eacute; les informations personnelles. Si vous utilisez un agent autoris&eacute; pour faire une demande, vous devez fournir &agrave; l&apos;agent autoris&eacute; une autorisation &eacute;crite pour le faire, et nous pouvons exiger que vous v&eacute;rifiiez votre identit&eacute; directement avec nous. Afin de prot&eacute;ger la s&eacute;curit&eacute; de vos informations personnelles, nous n&apos;honorerons pas une demande si nous ne pouvons pas v&eacute;rifier votre identit&eacute; ou votre autorit&eacute; pour faire la demande et confirmer que les informations personnelles vous concernent. La m&eacute;thode et les informations utilis&eacute;es pour v&eacute;rifier votre identit&eacute; varient en fonction de la nature de la demande et peuvent</p>
<p class="privacy-content-p">inclure votre adresse e-mail, une pi&egrave;ce d&apos;identit&eacute; gouvernementale et/ou un affidavit sign&eacute;. Vous avez le droit de ne pas recevoir de traitement discriminatoire pour l&apos;exercice de l&apos;un de vos droits &agrave; la vie priv&eacute;e.</p>
<p class="privacy-content-p">Nous avons collect&eacute; les cat&eacute;gories d&apos;informations personnelles identifi&eacute;es dans le tableau ci-dessus aupr&egrave;s d&apos;individus au cours des douze (12) derniers mois, et nous continuerons &agrave; le faire &agrave; l&apos;avenir.</p>
<p class="privacy-content-p">DROITS DES UTILISATEURS EU/UK</p>
<p class="privacy-content-p">Si vous &ecirc;tes situ&eacute; dans l&apos;Espace &eacute;conomique europ&eacute;en ou au Royaume-Uni et que le R&egrave;glement g&eacute;n&eacute;ral sur la protection des donn&eacute;es (&laquo; RGPD &raquo;) ou une loi similaire s&apos;applique, en tant que personne concern&eacute;e, vous disposez des droits suivants.</p>
<p class="privacy-content-p">La Soci&eacute;t&eacute; est g&eacute;n&eacute;ralement un sous-traitant pour Votre Administrateur des informations collect&eacute;es via le Service. Nous sommes un responsable du traitement de certaines donn&eacute;es personnelles que vous nous fournissez directement ou que nous collectons directement aupr&egrave;s de vous. Les droits de cette section s&apos;appliquent uniquement aux donn&eacute;es personnelles que nous traitons en tant que responsable du traitement.</p>
<p class="privacy-content-p">Droit d&apos;acc&egrave;s - Ce droit permet aux personnes d&apos;obtenir la confirmation que des donn&eacute;es personnelles les concernant sont trait&eacute;es ou non et de donner acc&egrave;s &agrave; ces donn&eacute;es personnelles Il permet &eacute;galement aux personnes de demander des d&eacute;tails sur le traitement de leurs donn&eacute;es personnelles, y compris, sans s&apos;y limiter, les cat&eacute;gories des destinataires auxquels les donn&eacute;es personnelles ont &eacute;t&eacute; ou seront divulgu&eacute;es et des finalit&eacute;s du traitement.</p>
<p class="privacy-content-p">Droit de rectification &ndash; Ce droit permet aux individus de rectifier toute donn&eacute;e personnelle inexacte les concernant.</p>
<p class="privacy-content-p">Droit de restreindre le traitement &ndash; Ce droit permet aux individus de bloquer ou de supprimer le traitement des donn&eacute;es personnelles dans certaines circonstances.</p>
<p class="privacy-content-p">Droit &agrave; l&apos;oubli &ndash; Ce droit est &eacute;galement connu sous le nom de &laquo; droit &agrave; l&apos;effacement &raquo;. C&apos;est le droit d&apos;un individu d&apos;avoir des donn&eacute;es personnelles effac&eacute;es ou d&apos;emp&ecirc;cher le traitement dans des circonstances sp&eacute;cifiques.</p>
<p class="privacy-content-p">Droit &agrave; la portabilit&eacute; des donn&eacute;es - Ce droit permet aux individus de d&eacute;placer, copier ou transf&eacute;rer des donn&eacute;es personnelles d&apos;un endroit &agrave; un autre de mani&egrave;re s&eacute;curis&eacute;e sans interrompre l&apos;int&eacute;grit&eacute; et la convivialit&eacute; des informations.</p>
<p class="privacy-content-p">Droit de s&apos;opposer au traitement - Ce droit permet aux individus de s&apos;opposer &agrave; certains types de traitement, y compris le marketing direct, le profilage et la fourniture &agrave; des fins de recherche scientifique ou historique et de statistiques.</p>
<p class="privacy-content-p">Nous ne traiterons vos donn&eacute;es personnelles que sur une base l&eacute;gale, qui sera g&eacute;n&eacute;ralement avec votre consentement, lorsque nous avons un int&eacute;r&ecirc;t l&eacute;gitime ou pour ex&eacute;cuter un contrat auquel nous sommes soumis (comme avec votre administrateur). De plus, si nous nous appuyons sur le consentement pour le traitement de vos donn&eacute;es personnelles, vous avez le droit de le retirer &agrave; tout moment et sans frais. Lorsque vous le faites, cela n&apos;affectera pas la lic&eacute;it&eacute; du traitement avant le retrait de votre consentement.</p>
<p class="privacy-content-p">Pour exercer vos droits en vertu du RGPD, veuillez nous contacter &agrave; [INS&Eacute;RER LES COORDONN&Eacute;ES]. Veuillez noter que pour que vous puissiez faire valoir ces droits, nous devrons peut-&ecirc;tre v&eacute;rifier votre identit&eacute; pour confirmer votre droit d&apos;acc&eacute;der &agrave; vos donn&eacute;es personnelles. Il s&apos;agit d&apos;une mesure de s&eacute;curit&eacute; visant &agrave; garantir que les donn&eacute;es personnelles ne soient divulgu&eacute;es &agrave; aucune personne qui n&apos;a pas le droit de les recevoir. Afin de v&eacute;rifier votre identit&eacute;, nous pourrions avoir besoin de recueillir plus de donn&eacute;es personnelles vous concernant que nous n&apos;en avons actuellement.</p>
<p class="privacy-content-p">Si vous &ecirc;tes situ&eacute; dans l&apos;UE ou au Royaume-Uni et que vous avez des r&eacute;clamations concernant nos pratiques de confidentialit&eacute;, vous avez le droit de vous plaindre aupr&egrave;s de votre autorit&eacute; de contr&ocirc;le.</p>
<p class="privacy-content-p">S&Eacute;CURIT&Eacute;</p>
<p class="privacy-content-p">Nous prenons la s&eacute;curit&eacute; de nos donn&eacute;es et informations tr&egrave;s au s&eacute;rieux. Nous prenons ce que nous pensons &ecirc;tre des pr&eacute;cautions raisonnables pour nous prot&eacute;ger contre tout acc&egrave;s non autoris&eacute; &agrave; nos syst&egrave;mes et pour emp&ecirc;cher la divulgation de donn&eacute;es &agrave; des tiers non autoris&eacute;s. Cependant, aucune m&eacute;thode de transmission sur Internet, ou m&eacute;thode de stockage &eacute;lectronique, n&apos;est s&eacute;curis&eacute;e &agrave; 100 %.</p>
<p class="privacy-content-p">MODIFICATIONS DE NOTRE POLITIQUE DE CONFIDENTIALIT&Eacute;</p>
<p class="privacy-content-p">Nous pouvons modifier cette politique de confidentialit&eacute; &agrave; notre discr&eacute;tion &agrave; tout moment. Si nous apportons des modifications, ces modifications appara&icirc;tront sur cette page de nos Services et nous vous informerons de toute modification importante. La date &agrave; laquelle la politique de confidentialit&eacute; a &eacute;t&eacute; r&eacute;vis&eacute;e pour la derni&egrave;re fois est identifi&eacute;e en haut de la page. Votre utilisation continue de nos services apr&egrave;s toute modification signifie que vous avez accept&eacute;, consentez et acceptez d&apos;&ecirc;tre li&eacute; par cette politique de confidentialit&eacute; r&eacute;vis&eacute;e.</p>
<p class="privacy-content-p">&Eacute;TATS-UNIS D&apos;AM&Eacute;RIQUE ET TRANSFERTS DE DONN&Eacute;ES TRANSFRONTALIERS</p>
<p class="privacy-content-p">Nous pouvons stocker vos informations aux &Eacute;tats-Unis d&apos;Am&eacute;rique et dans d&apos;autres endroits. En acc&eacute;dant &agrave; nos Services, vous nous donnez librement et sp&eacute;cifiquement votre consentement &agrave; exporter vos informations aux &Eacute;tats-Unis et &agrave; d&apos;autres endroits d&eacute;sign&eacute;s par nous et &agrave; leur stockage et utilisation comme sp&eacute;cifi&eacute; dans la pr&eacute;sente Politique de confidentialit&eacute;. Les informations stock&eacute;es aux &Eacute;tats-Unis peuvent faire l&apos;objet de demandes l&eacute;gales de la part des tribunaux ou des autorit&eacute;s charg&eacute;es de l&apos;application de la loi aux &Eacute;tats-Unis.</p>
<p class="privacy-content-p">NOUS CONTACTER</p>
<p class="privacy-content-p">Si vous avez des questions sur cette politique de confidentialit&eacute;, veuillez nous contacter &agrave; CONTACT@GPS4WORLD.ma</p>
</div>
</section>
    </main>
  );
};
export default PolitiquedeconfidentialiteContent;
