import React from "react";
import { WhyUs } from "../../components/whyus/WhyUs";
import { Hero } from "./Hero";

export const Home = () => {
  return (
    <>
      <Hero />
      <WhyUs />
    </>
  );
};
