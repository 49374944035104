import camion from "../../images/camion.png";
import actif from "../../images/actifs.png";
import dog from "../../images/dog.png";

const data = [
  {
    classIcon: camion,
    header: "Suivi GPS de vos véhicules et leurs carburant",
    text: "motos, voitures, camions,  tracteurs et autres véhicules",
  },
  {
    classIcon: actif,
    header: "Suivi GPS de Vos actifs",
    text: "Nos outils de suivi sont auto-alimentes avec une autonomie de la batterie inégalée ",
  },
  {
    classIcon: dog,
    header: "Suivi GPS des Personnes ou Animaux",
    text: "Personnel professionnel en déplacement, les enfants, les malades atteint d'alhzeimer etc...",
  },
];
export default data;
