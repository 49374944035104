const _nav = [
  {
    name: "Accueil",
    to: "/home",
  },
  {
    name: "à propos de nous",
    to: "/about",
  },
  {
    name: "Services et Produits",
    to: "/servicesAndProducts",
  },
  {
    name: "Produits",
    to: "Produits",
  },
  {
    name: "Contactez Nous",
    to: "contact",
  },
];

export default _nav;
