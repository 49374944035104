

import React from 'react';
 

import Footer from "./components/footer/footer";
import Navbar from "./components/navigation/Navbar";

import Home from "./components/Home";
import Politiquedeconfidentialite from "./components/Politiquedeconfidentialite";

import { BrowserRouter as Router, Routes, Route} from 'react-router-dom';
 
function App() {
return (
    <Router  basename={process.env.PUBLIC_URL}>
      <Navbar />
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route path="/politique-de-confidentialite" element={<Politiquedeconfidentialite />} />
        </Routes>
      <Footer />
    </Router>
);
}
  
export default App;