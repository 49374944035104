import React from "react";
import MainContent from "./MainContent";

import CookieConsent from "react-cookie-consent";
function Home() {
    return (
      <>
       
        <MainContent />
 
        <CookieConsent
        location="bottom"
        buttonText="J'accepte !"
        cookieName="politque-de-confidentialite"
        style={{ background: "#2B373B" }}
        buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
        expires={30}
        >
        En poursuivant votre navigation sur ce site, vous acceptez l'utilisation de cookies pour vous proposer des contenus et services adaptés à vos centres d'intérêts..{" "}
        <span style={{ fontSize: "10px" }}>Lire Plus sur notre <a href="/politique-de-confidentialite">Politique de Confidentialité</a></span>
        </CookieConsent>
      </>
    );
  }

export default Home;