import satellite from "../../images/sattelite.png";
import memory from "../../images/memory.png";
import fuel from "../../images/fuel.png";
import temperature from "../../images/temperature.png";
import wheel from "../../images/wheel.png";
import door from "../../images/door.png";
import camera from "../../images/camera.png";
import battery from "../../images/battery.png";
import car from "../../images/car.png";
//import comment from "../../images/comment.png";
import immobilisation from "../../images/bleuicons13.png";
import chauffeur from "../../images/bleuicons09.png";
import mesure from "../../images/bleuicons14.png";
const data = [
  {
    title: "Localisation précise par GNSS",
    img: satellite,
  },
  {
    title: "Comportement de conduite",
    img: wheel,
  },
  {
    title: "Détection et Reconstruction d'accident",
    img: car,
  },
  {
    title: "Vol de Gasoil",
    img: fuel,
  },
  {
    title: "Mémoire de vos trajets pendant 90 jours",
    img: memory,
  },

  {
    title: "Immobilisation à Distance",
    img: immobilisation,
  },
  {
    title: "Mesure de Poids",
    img: mesure,
  },
  {
    title: "Détection de l'ouverture des capots",
    img: door,
  },
  {
    title: "Batterie interne",
    img: battery,
  },
  {
    title: "Température du frigo",
    img: temperature,
  },
  {
    title: "Identification chauffeur",
    img: chauffeur,
  },
  {
    title: "Caméras de surveillance",
    img: camera,
  },
];

export default data;
